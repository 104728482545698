import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-b587dd02"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "pl-5",
  "aria-label": "breadcrumb"
};
const _hoisted_2 = {
  class: "breadcrumb",
  style: {
    "margin-top": "1rem"
  }
};
const _hoisted_3 = {
  class: "breadcrumb-item"
};
const _hoisted_4 = {
  class: "breadcrumb-item",
  "aria-current": "page"
};
const _hoisted_5 = {
  class: "breadcrumb-item",
  "aria-current": "page"
};
const _hoisted_6 = {
  class: "container-xxl my-md-4 bd-layout",
  id: "main-article"
};
import { ref } from "@vue/reactivity";
import { computed, onDeactivated, onMounted, onUnmounted } from "vue";
import { useRoute, useRouter, onBeforeRouteUpdate } from "vue-router";
import BlogContentVue from "../../../components/blog/BlogContent.vue";
import PageFooter from "../../../components/common/PageFooter.vue";
import PageHeader from "../../../components/common/PageHeader.vue";
export default {
  __name: 'BlogPage',
  props: ['categorySname', 'blogName', 'path'],

  setup(__props) {
    const probs = __props;
    var route = useRoute();
    var router = useRouter();
    const showName = computed(() => {
      let res = probs.blogName.split(".");
      return res[res.length - 2];
    });
    onBeforeRouteUpdate((to, from, next) => {
      document.title = to.params.blogName.split('.md')[0];
      console.log(to, from);
      next();
    });
    const blogTitle = computed(() => {
      return probs.blogName?.replace(/\.md$/, '');
    });
    return (_ctx, _cache) => {
      const _component_router_link = _resolveComponent("router-link");

      return _openBlock(), _createElementBlock("div", null, [_createVNode(PageHeader, null, {
        topLeft: _withCtx(() => [_createElementVNode("nav", _hoisted_1, [_createElementVNode("ol", _hoisted_2, [_createElementVNode("li", _hoisted_3, [_createVNode(_component_router_link, {
          to: "/blog"
        }, {
          default: _withCtx(() => [_createTextVNode("Home")]),
          _: 1
        })]), _createElementVNode("li", _hoisted_4, _toDisplayString(__props.categorySname), 1), _createElementVNode("li", _hoisted_5, _toDisplayString(_unref(showName)), 1)])])]),
        _: 1
      }), _createElementVNode("div", _hoisted_6, [_createVNode(BlogContentVue, {
        blogPath: __props.path,
        blogTitle: _unref(blogTitle)
      }, null, 8, ["blogPath", "blogTitle"])]), _createVNode(PageFooter)]);
    };
  }

};