export default {
    categories: [
        {
            name: '页面演示',
            path: 'pages',
        },
        {
            name: "文件处理",
            path: "fileandblob",
        },
        {
            name: "CSS动画",
            path: 'cssanimation',
        }, {
            name: "CSS布局",
            path: 'csslayout',
        }, {
            name: "Vue",
            path: 'vue',
        }
    ]
}