export default {
    categories: [
        {
            name: '博客',
            path: 'blog',
        },
        {
            name: '演示',
            path: 'demo',
        },
        {
            name: '阅读',
            path: 'book',
        }
    ]
}