import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, KeepAlive as _KeepAlive, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue";
import DevicePixelRatio from '@/utils/DevicePixelRatio';
import { onMounted } from 'vue';
export default {
  __name: 'App',

  setup(__props) {
    onMounted(() => {
      new DevicePixelRatio().init();
    });
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");

      return _openBlock(), _createBlock(_component_router_view, null, {
        default: _withCtx(({
          Component,
          route
        }) => [(_openBlock(), _createBlock(_KeepAlive, null, [route.meta.keepAlive ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
          key: route.path
        })) : _createCommentVNode("", true)], 1024)), !route.meta.keepAlive ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
          key: route.path
        })) : _createCommentVNode("", true)]),
        _: 1
      });
    };
  }

};