export default {
  data() {
    return {
      paragraphA: '',
      textB: '',
      paragraphsCandD: [{
        paragraphC: '',
        textD: ''
      }],
      generatedMessage: '',
      copySuccess: false
    };
  },

  methods: {
    addCDPair() {
      this.paragraphsCandD.push({
        paragraphC: '',
        textD: ''
      });
    },

    generateMessage() {
      if (this.paragraphA && this.textB && this.paragraphsCandD.every(p => p.textD)) {
        // const similarParts = this.paragraphsCandD.map(p => `段落"${p.paragraphC}"\n中的\n"${p.textD}"`).join("以及");
        const similarParts = this.paragraphsCandD.map(p => `“${p.textD}”`).join("和");
        this.generatedMessage = `帮我改写段落"${this.paragraphA}"\n中的\n"${this.textB}"，\n避免与\n${similarParts}\n中的内容相似`;
        alert(this.generatedMessage);
      } else {
        alert("请填写所有输入框。");
      }
    },

    copyMessage() {
      navigator.clipboard.writeText(this.generatedMessage).then(() => {
        this.copySuccess = true;
        setTimeout(() => this.copySuccess = false, 2000); // Reset after 2 seconds
      }).catch(err => {
        console.error('Failed to copy text: ', err);
        alert('复制失败，请手动复制文本。');
      });
    }

  }
};