import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-0e2d7783"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  key: 0
};
const _hoisted_2 = {
  key: 1
};
import { useRoute } from "vue-router";
import { defineAsyncComponent, watch } from "vue";
import { useScreenType, smallScreen, largeScreen } from "@/stores/screenType";
export default {
  __name: 'DefaultDemoContent',

  setup(__props) {
    const screenType = useScreenType(); // console.log(screenType);

    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock(_Fragment, null, [_unref(screenType) === _unref(smallScreen) ? (_openBlock(), _createElementBlock("span", _hoisted_1, "左滑显示导航栏")) : _createCommentVNode("", true), _unref(screenType) !== _unref(smallScreen) ? (_openBlock(), _createElementBlock("span", _hoisted_2, "点击左侧导航栏查看示例")) : _createCommentVNode("", true)], 64);
    };
  }

};